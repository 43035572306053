<template>
  <div class="user-select">
    <div class="trigger" @click="handleTrigger" v-loading="state.detailSelectState">
      <!-- <el-input v-model=""></el-input> -->
      <template v-if="state.detailSelectList.length">
        <slot name="label" :list="state.detailSelectList">
          <el-tooltip :content="viewText" placement="top" effect="light">
            <div class="view-text ellipsis">
              <template v-for="(item, index) in state.detailSelectList" :key="item.id">
                <span>{{ item.label }}</span>
                <span v-if="index !== state.detailSelectList.length - 1">、</span>
              </template>
            </div>
          </el-tooltip>
          <!-- <div class="select-item" v-for="user in state.selectList" :key="user.id">{{ user.label }}</div> -->
        </slot>
      </template>
      <div v-else class="placeholder">{{ placeholder }}</div>
    </div>
    <OrganizationSelect
      popTitle="选择成员"
      :filter="true"
      :config="state.config"
      :isLoadUser="true"
      selectType="user"
      @cancel="cancelOrgan"
      @confirm="confirmOrgan"
      @loadScroll="loadScroll"
      :select_data="state.selectList"
    />
    <!-- <transfer-dialog
      ref="transferDialogComponent"
      title="选择人员"
      selectType="user"
      :nodeKey="nodeKey"
      labelKey="label"
      childrenKey="childList"
      :lists="state.list"
      v-model:visible="state.visible"
      :selecteds="state.selectList"
      @confirm="confirm"
      @addItem="addItem"
      @cancel="cancel"
      @loadScroll="loadScroll"
      @delSelectItem="delDialogItem"
      @cancelCheckAll="cancelCheckAll"
      :single="single"
      :rootName="state.rootName"
      :rootId="state.rootId"
      :search="onSearch"
    ></transfer-dialog> -->
  </div>
</template>
<script>
import OrganizationSelect from '@/components/organization-select/index.vue'
// import TransferDialog from '@/components/transfer-dialog/index.vue'
import { computed, onUnmounted, reactive, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getAllEmployeeList, getRoleUser } from '@/apis'
export default {
  name: 'userSelect',
  components: { OrganizationSelect },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    single: {
      type: Boolean,
      default: false
    },
    nodeKey: {
      type: String,
      default: 'userid'
    },
    placeholder: {
      type: String,
      default: '选择员工'
    }
  },
  emits: ['update:modelValue', 'ok', 'confirm', 'change'],
  setup(props, { emit }) {
    const transferDialogComponent = ref(null)
    const route = useRoute()
    const ID = route.params.id

    const updateValue = value => {
      emit('update:modelValue', value) // 传递的方法
    }
    const enterType = computed(() => {
      return ID ? 'edit' : 'add'
    })
    const state = reactive({
      visible: false,
      list: [],
      rootName: '',
      rootId: '',
      selectList: [],
      tempMap: new Map(),
      rootData: [],
      roleUserPage: {
        pageNo: 1,
        pageSize: 10
      },
      loadState: true,
      detailSelectList: [], // 详情返回的
      detailSelectState: false, //加载状态
      detailSelectListAll: [], //详情返回的
      detailSelectLists: [], // 详情返回的（不赋值）
      config: {
        show: false
      }
    })
    const getRoleUserData = () => {
      return new Promise((resolve, reject) => {
        getRoleUser(Object.assign(state.roleUserPage, { roleId: ID }))
          .then(res => {
            let result = (res.data || []).map(item => {
              let obj = {
                id: item.id,
                label: item.name,
                name: item.name,
                user: true,
                userid: item.userNo,
                type: 'user'
              }
              obj[props.nodeKey] = item[props.nodeKey]
              return obj
            })
            //返回数据小于页码数量不需要请求
            state.loadState = !(result.length < state.roleUserPage.pageSize)
            resolve(result)
            console.log('result: ', result)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
    const initRoleUserData = () => {
      state.detailSelectState = true
      if (enterType.value === 'add') {
        return
      }
      getRoleUserData().then(async res => {
        state.detailSelectList = await res.slice(0, 10)
        // state.detailSelectLists = await res.slice(0, 10)
        console.log('state.detailSelectList: ', state.detailSelectList, res)
        state.detailSelectListAll = res
        state.detailSelectState = false
        updateValue(state.detailSelectListAll.map(item => item[props.nodeKey]))
      })
    }
    const viewText = computed(() => {
      return state.detailSelectList.map(item => item.label).join('、')
    })
    const loadingPage = (flag = false) => {
      if (enterType.value === 'add') {
        return
      }
      state.roleUserPage.pageNo += 1
      // nextTick(() => {
      //   transferDialogComponent.value.setRightLoading(true)
      // })
      getRoleUserData().then(res => {
        state.selectList = flag ? state.selectList.concat(res) : res
        // nextTick(() => {
        //   transferDialogComponent.value.setLoadingSelectList(state.selectList)
        //   transferDialogComponent.value.setRightLoading(false)
        // })
      })
    }
    const loadScroll = (flag = true) => {
      if (!state.loadState) {
        return
      }
      loadingPage(flag)
    }

    const handleTrigger = () => {
      if (enterType.value === 'edit') {
        state.roleUserPage.pageNo = 1
        getRoleUserData()
        state.selectList = state.detailSelectListAll
      }
      console.log('弹出窗口', enterType.value, state.selectList)
      state.config.show = true
      state.visible = true
    }
    const triggerDialog = () => {
      resetList(false)
      if (enterType.value === 'edit') {
        state.selectList = []
        state.roleUserPage.pageNo = 1
        getRoleUserData()
      }
      state.config.show = true
      state.visible = true
    }
    const resetList = (checked = false) => {
      state.list.forEach(item => (item.checked = checked))
    }
    const confirm = list => {
      console.log('confirm------list: ', list)
      state.selectList = list
      state.detailSelectListAll = list
      state.detailSelectList = list.slice(0, 10)

      const ids = list.map(item => item[props.nodeKey])
      console.log('ids: ', ids)
      updateValue(ids)
      emit('ok', ids, list)
      emit('confirm', ids, list)
      emit('change', ids)
      // 数据还原
      state.list = state.rootData
      state.selectList = []
      resetList()
    }
    const cancel = () => {
      // 数据还原
      state.list = state.rootData

      emit('cancel')
    }
    const delDialogItem = data => {
      console.log('delDialogItem:---------user ', delDialogItem)
      // 删除
      emit('delDialogSelectItem', data)
    }
    const cancelCheckAll = data => {
      // 删除
      emit('cancelCheckAll', data)
    }
    const addItem = data => {
      // 删除
      emit('addItem', data)
    }

    const searUserList = async name => {
      try {
        const { data } = await getAllEmployeeList({ name })
        state.list = (data || []).map(item => {
          item.type = 'user'
          item.label = item.name
          item.userid = item.userNo
          return item
        })
      } catch (error) {
        console.log('searUserList---error: ', error)
      }
    }
    const onSearch = text => {
      if (!text) {
        transferDialogComponent.value.initGetTreeNode()
      } else {
        searUserList(text)
      }
    }
    const getSelectLength = () => {
      //获取右边当前选择的数据
      if (transferDialogComponent.value) {
        return transferDialogComponent.value.getSelectList()
      }
    }

    const stop = watch(
      () => props.modelValue,
      value => {
        if (!(value && value.length > 0)) {
          state && (state.detailSelectList = [])
          emit('ok', props.modelValue, state?.selectList ?? [])
        }
      },
      { immediate: true, deep: true }
    )

    onUnmounted(() => typeof stop === 'function' && stop())
    const cancelOrgan = () => {
      state.config.show = false
    }
    const confirmOrgan = (list, oldList) => {
      const ids = list.map(item => item[props.nodeKey])
      console.log('confirm------list: ', oldList, ids)
      const arr = []
      const delArr = []
      for (let i = 0; i < ids.length; i++) {
        if (!oldList.filter(item => item.userNo === ids[i]).length) {
          arr.push(ids[i])
        }
      }
      for (let i = 0; i < oldList.length; i++) {
        if (!ids.filter(item => item === oldList[i].userNo).length) {
          delArr.push(oldList[i])
        }
      }
      state.selectList = list
      state.detailSelectListAll = list
      state.detailSelectList = list.slice(0, 10)
      console.log('ids: ', ids, arr, delArr)
      updateValue(ids)
      emit('ok', ids, list)
      emit('confirm', ids, list)
      emit('change', ids)
      addItem(arr)
      delDialogItem(delArr)

      // 数据还原
      state.list = state.rootData
      state.selectList = []
      state.config.show = false
      resetList()
    }
    return {
      state,
      transferDialogComponent,
      viewText,
      handleTrigger,
      triggerDialog,
      confirm,
      cancel,
      onSearch,
      getRoleUserData,
      loadScroll,
      initRoleUserData,
      delDialogItem,
      cancelCheckAll,
      getSelectLength,
      loadingPage,
      addItem,
      cancelOrgan,
      confirmOrgan
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
