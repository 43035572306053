<template>
  <el-dialog
    width="750px"
    v-model="innerVisible"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
  >
    <template #title>
      <span class="dialog-hander">{{ popTitle }}</span>
    </template>
    <div class="wl-transfer transfer" :style="{ width, height }">
      <!-- 左侧穿梭框 原料框 -->
      <div class="transfer-left">
        <!-- 内容区 -->
        <div class="transfer-main">
          <!-- <slot name="from"></slot> -->
          <el-input
            v-if="filter"
            :placeholder="placeholder"
            v-model="state.filterText"
            size="small"
            class="filter-tree"
          ></el-input>
          <el-tree
            class="from-tree"
            :lazy="lazy"
            :load="leftloadNode"
            :node-key="node_key"
            :props="defaultProps"
            :data="state.departmentTree"
            :default-expand-all="false"
            :expand-on-click-node="false"
            :filter-node-method="filterFrom"
            :default-expanded-keys="expanded_keys"
            @node-click="fromTreeChecked"
            ref="from-tree"
          >
            <template class="custom-tree-node" #default="{ node, data }">
              <p v-if="data.showMore" class="tree-show-more">
                <i class="el-icon-loading" v-if="data.loading"></i>
                <span>加载更多</span>
              </p>
              <span v-else style="display: block; width: 95%" :key="data.id">
                <!-- <i
                  class="fa"
                  :class="[data.userid ? 'fa-user' : 'fa-folder']"
                  style="color: #7fa5cf; font-size: 12px"
                ></i> -->
                <img
                  :src="data.userid ? ICON_PERSONNEL : ICON_FOLDER"
                  style="width: 16px; float: left; margin-top: 4px"
                />
                &nbsp; {{ node.label }}&nbsp;
                <!-- <el-icon v-show="data.selected" style="color: #7fa5cf; font-size: 12px"><check /></el-icon> -->
                <i
                  class="el-icon-circle-check"
                  style="color: #7fa5cf; font-size: 16px"
                  v-show="
                    data.selected ||
                    state.to_data.filter(item => item.userid === data.userid || item.id === data.id).length
                  "
                ></i>
              </span>
            </template>
          </el-tree>
        </div>
      </div>
      <!-- 右侧穿梭框 目标框 -->
      <div class="transfer-right">
        <!-- 内容区 -->
        <div class="transfer-main" v-infinite-scroll="rightLoadScroll" infinite-scroll-immediate="false">
          <div class="box-row-title">{{ title }}</div>
          <div v-for="(item, index) of state.to_data" :key="index" class="box-row">
            <span style="display: block; width: 95%">
              <!-- <i class="fa" :class="[item.userid ? 'fa-user' : 'fa-folder']" style="color: #7fa5cf; font-size: 12px"></i> -->
              <img
                :src="item.userid ? ICON_PERSONNEL : ICON_FOLDER"
                style="width: 16px; float: left; margin-top: 7px"
              />
              &nbsp;{{ item.name }}&nbsp;
              <!-- <el-icon style="color: #7fa5cf; font-size: 12px; float: right" @click="toTreeChecked(item)">
                <close />
              </el-icon> -->
              <i
                class="el-icon-circle-close"
                style="color: #7fa5cf; font-size: 16px; float: right; margin-top: 7px"
                @click="toTreeChecked(item)"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="colseVisible">关闭</el-button>
        <el-button type="primary" @click="confirmSelect">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import { computed, watch, reactive, nextTick } from 'vue'
import { treeRootDepartment, treeEmployeeDepartment, treeNodeDepartment } from '@/apis'
import { ICON_FOLDER, ICON_PERSONNEL } from '@/common/const'
export default {
  props: {
    // 宽度
    width: {
      type: String,
      default: '100%'
    },
    // 高度
    height: {
      type: String,
      default: '320px'
    },
    popTitle: {
      type: String,
      default: () => '选择部门'
    },
    // 标题
    title: {
      type: String,
      default: () => '已选择的部门或成员'
    },
    // 源数据
    // from_data: {
    //   type: Array,
    //   default: () => []
    // },
    expanded_keys: {
      type: Array,
      default: () => [1]
    },
    select_data: {
      type: Array,
      default: () => []
    },
    // 选中数据
    // to_data: {
    //   type: Array,
    //   default: () => []
    // },
    // el-tree 配置项
    defaultProps: {
      type: Object,
      default: () => {
        return {
          label: 'name',
          children: 'childList',
          isLeaf: function (data, node) {
            if (node.level == 0 || data.childList) {
              return false
            }
            return true
          }
        }
      }
    },
    // el-tree node-key 必须唯一
    node_key: {
      type: String,
      default: 'id'
    },
    // 自定义 pid参数名
    pid: {
      type: String,
      default: 'pid'
    },
    // 是否启用筛选
    filter: {
      type: Boolean,
      default: false
    },
    // 是否展开所有节点
    openAll: {
      type: Boolean,
      default: false
    },
    // 筛选placeholder
    placeholder: {
      type: String,
      default: '输入关键字进行过滤'
    },
    // 是否开启arrayToTree
    arrayToTree: {
      type: Boolean,
      default: false
    },
    // 是否启用懒加载
    lazy: {
      type: Boolean,
      default: true
    },
    // 懒加载的回调函数
    lazyFn: Function,
    // 是否单选
    single: {
      type: Boolean,
      default: false
    },
    // 选择类型 all-全部可选，dept-只能选部门，user-只能选人
    selectType: {
      type: String,
      default: 'all'
    },
    config: {
      type: Object,
      default: () => {
        return {
          show: false
        }
      }
    },
    // 是否要加载人员
    isLoadUser: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:config.show', 'cancel', 'confirm', 'loadScroll'],
  setup(props, { emit }) {
    const state = reactive({
      to_data: [],
      filterText: '', // 源数据筛选
      selectNodes: [],
      currentNode: {
        id: 1,
        departmentSize: 20,
        departmentNo: 1,
        userSize: 20,
        userNo: 1,
        isLoadingDepartment: true
      },
      cachesLoadNode: [],
      departmentTree: [],
      oldList: []
    })
    // 异步加载左侧
    const leftloadNode = async (node, resolve) => {
      //   node.childNodes = []
      console.log('异步加载左侧', node, node.level)
      if (node.level === 0) {
        return resolve(state.departmentTree)
      }
      if (props.isLoadUser && node.level === 1) {
        let people = await treeEmployeeDepartment({
          departmentId: state.currentNode.id,
          pageNo: state.currentNode.userNo,
          pageSize: state.currentNode.userSize
        })
        people.data.list.forEach(item => {
          if (state.to_data.filter(items => items.userid === item.userid).length) {
            item.selected = true
          }
        })
        let concats = state.departmentTree[0].childList.concat(people.data.list)
        if (people.data.list.length >= 20) {
          // if(!props.from_data[0].childList.filter(keys => keys.showMore).length){
          concats.push({
            showMore: true,
            parent_id: state.departmentTree[0].id,
            loading: false
          })
          // }
          duplicateRemoval(node, resolve, false)
        }
        console.log('查看接口返回值', concats)
        return resolve(concats)
      } else if (!props.isLoadUser && node.level === 1) {
        let concats = state.departmentTree[0].childList
        return resolve(concats)
      }
      if (node.level > 1) {
        duplicateRemoval(node, resolve)
        let data = await treeNodeDepartment({
          parent_id: state.currentNode.id,
          pageNo: state.currentNode.departmentNo,
          pageSize: state.currentNode.departmentSize
        })
        let people
        if (props.isLoadUser) {
          people = await treeEmployeeDepartment({
            departmentId: state.currentNode.id,
            pageNo: state.currentNode.userNo,
            pageSize: state.currentNode.userSize
          })
        }
        if (props.isLoadUser && data.code == 200 && people.code == 200) {
          let optimztion = data.data.list.concat(people.data.list)

          if (data.data.list.length >= 20 || people.data.list.length >= 20) {
            optimztion.push({ showMore: true, parent_id: node.data.id, loading: false })
          }
          console.log('查看接口返回值', optimztion)
          optimztion.forEach(item => {
            if (
              state.to_data.filter(items => items.id === item.id || (item.userid && items.userid === item.userid))
                .length
            ) {
              item.selected = true
            }
          })
          return resolve(optimztion)
        } else if (!props.isLoadUser && data.code == 200) {
          let optimztion = data.data.list
          if (data.data.list.length >= 20) {
            optimztion.push({ showMore: true, parent_id: node.data.id, loading: false })
          }
          optimztion.forEach(item => {
            if (state.to_data.filter(items => items.id === item.id).length) {
              item.selected = true
            }
          })
          return resolve(optimztion)
        }
      }
      return resolve([])
    }
    const loadNode = async (propsNode, node) => {
      propsNode.loading = true
      let cachesCurrentNode = state.cachesLoadNode.filter(item => item.id == node.data.parent_id)[0]

      cachesCurrentNode.departmentNo++
      cachesCurrentNode.userNo++
      let data = cachesCurrentNode.isLoadingDepartment
        ? await treeNodeDepartment({
            parent_id: cachesCurrentNode.id,
            pageNo: cachesCurrentNode.departmentNo,
            pageSize: cachesCurrentNode.departmentSize
          })
        : { data: { list: [] } }
      data.data.list.forEach(item => {
        if (state.to_data.filter(items => items.id === item.id).length) {
          item.selected = true
        }
      })
      let people
      if (props.isLoadUser) {
        people = await treeEmployeeDepartment({
          departmentId: cachesCurrentNode.id,
          pageNo: cachesCurrentNode.userNo,
          pageSize: cachesCurrentNode.userSize
        })
        people.data.list.forEach(item => {
          if (state.to_data.filter(items => items.userid === item.userid).length) {
            item.selected = true
          }
        })
      }

      let catchData = node.parent.childNodes.map(item => item.data)

      let index = null
      catchData.forEach((item, i) => {
        if (item.childList) index = i
      })
      catchData.splice(index + 1, 0, ...data.data.list)

      if (props.isLoadUser) {
        catchData.splice(catchData.length - 1, 0, ...people.data.list)
      }

      if (props.isLoadUser && data.data.list.length < 20 && people.data.list.length < 20) {
        catchData.splice(catchData.length - 1, 1)
      } else if (!props.isLoadUser && data.data.list.length < 20) {
        catchData.splice(catchData.length - 1, 1)
      }

      cachesCurrentNode.resolveFn(catchData)
      propsNode.loading = false
    }
    const duplicateRemoval = (node, resolve, isRoot = true) => {
      state.currentNode.id = node.data.id
      state.currentNode.departmentNo = 1
      state.currentNode.userNo = 1
      state.currentNode.isLoadingDepartment = isRoot
      let hasTrue = state.cachesLoadNode.filter(item => item.id == node.data.id)

      if (hasTrue.length) {
        hasTrue[0].departmentNo = 1
        hasTrue[0].userNo = 1
        hasTrue[0].resolveFn = resolve
        return false
      }
      state.cachesLoadNode.push({ ...state.currentNode, resolveFn: resolve })
    }
    // 源树选中事件 - 是否禁用穿梭按钮
    const fromTreeChecked = (data, node) => {
      if (data.showMore) {
        nextTick(() => {
          loadNode(data, node)
        })
        return
      }
      if (data.selected) {
        unselectedNode(data, node)
      } else {
        // 控制选择类型
        if ((props.selectType === 'user' && !data.userid) || (props.selectType === 'dept' && data.userid)) {
          return
        }
        selectedNode(data, node)
      }
    }
    // 目标树选中事件 - 是否禁用穿梭按钮
    const toTreeChecked = data => {
      let index = indexOf(data)
      if (index !== -1) {
        let node = state.selectNodes[index]
        unselectedNode(data, node)
      }
    }
    // 选中
    const selectedNode = (data, node) => {
      if (data.id !== 'a') {
        unselectedNode(data, node)
        // if (props.single) {
        //   // 先全部反选
        //   for (let index = state.to_data.length - 1; index >= 0; index--) {
        //     unselectedNode(state.to_data[index])
        //   }
        // }
        // data.selected = true
        // this.$set(data, 'selected', true)
        // 兼容老接口
        // data.label = data.name
        // state.to_data.push(data)
        // state.selectNodes.push(node)
      }
    }
    // 反选
    const unselectedNode = (data, node) => {
      data.selected = false
      // this.$set(data, 'selected', false)
      let index = indexOf(data)
      console.log('删除', index, state.to_data)
      if (index !== -1) {
        state.to_data.splice(index, 1)
        state.selectNodes.splice(index, 1)
        if (!state.to_data.length) {
          emit('loadScroll', false)
        }
      } else {
        data.selected = true
        data.label = data.name
        state.to_data.push(data)
        state.selectNodes.push(node)
      }
    }
    // 过滤
    const filterFrom = (value, data) => {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    }
    const indexOf = data => {
      for (let i = 0; i < state.to_data.length; i++) {
        if (state.to_data[i].id === data.id || (state.to_data[i].userid && state.to_data[i].userid === data.userid)) {
          return i
        }
      }
      return -1
    }
    const innerVisible = computed({
      get() {
        return props.config.show
      },
      set(val) {
        emit('update:config.show', val)
      }
    })
    const loadDepartments = () => {
      if (!props.config.show) return
      treeRootDepartment({ pageNo: 1, pageSize: 5000 }).then(rs => {
        if (rs.isOk) {
          rs.data.childList.forEach(item => {
            if (
              state.to_data.filter(items => item.id === items.id || (item.userid && item.userid === items.userid))
                .length
            ) {
              item.selected = true
            }
          })
          const arr = [rs.data]
          state.departmentTree = arr.map(item => {
            let selected = false
            if (
              state.to_data.filter(items => item.id === items.id || (item.userid && item.userid === items.userid))
                .length
            ) {
              selected = true
            }
            return {
              ...item,
              selected
            }
          })
        }
      })
    }
    const colseVisible = () => {
      state.to_data = []
      emit('cancel', false)
    }
    const confirmSelect = () => {
      const list = JSON.parse(JSON.stringify(state.to_data))
      console.log(list)
      if (!list.length) {
        ElMessage.warning('请选择至少一条数据')
        return
      }
      state.to_data = []
      emit('confirm', list, state.oldList)
    }
    const getSeach = async val => {
      let data = await treeNodeDepartment({
        pageNo: state.currentNode.departmentNo,
        pageSize: state.currentNode.departmentSize,
        search: val
      })
      let people
      if (props.isLoadUser) {
        people = await treeEmployeeDepartment({
          pageNo: state.currentNode.userNo,
          pageSize: state.currentNode.userSize,
          search: val
        })
      }
      const arr = []
      if (props.isLoadUser && data.isOk && people.isOk) {
        arr.push(...data.data.list, ...people.data.list)
        arr.forEach(items => {
          if (
            state.to_data.filter(item => item.id === items.id || (items.userid && item.userid === items.userid)).length
          ) {
            items.selected = true
          }
        })
      } else if (!props.isLoadUser && data.isOk) {
        arr.push(...data.data.list)
        arr.forEach(items => {
          if (state.to_data.filter(item => item.id === items.id).length) {
            items.selected = true
          }
        })
      }
      state.departmentTree = JSON.parse(JSON.stringify(arr))
      console.log('搜索数据：', arr)
    }
    const debounce = function debounce(fn, delay) {
      let timer = null
      return function () {
        clearTimeout(timer)
        let args = arguments
        let that = this
        timer = setTimeout(function () {
          fn.apply(that, args)
        }, delay)
      }
    }
    const rightLoadScroll = () => {
      //滚动加载
      emit('loadScroll')
    }
    const cutarray = arr => {
      let obj = {}
      let brr = []
      arr.forEach(item => {
        if (obj[item.userid] == undefined && obj[item.id] === undefined) {
          obj[item.userid || item.id] = 1
          brr.push(item)
        } else {
          obj[item.userid || item.id] += 1
        }
      })
      return brr
    }
    watch(
      () => state.filterText,
      // 左侧 数据筛选
      debounce(val => {
        console.log(val)
        if (val) {
          state.currentNode.departmentSize = 20
          state.currentNode.departmentNo = 1
          state.currentNode.userSize = 20
          state.currentNode.userNo = 1
          getSeach(val)
        } else {
          loadDepartments()
        }
      }, 500)
    )
    watch(
      () => props.config.show,
      // 左侧 数据筛选
      val => {
        if (val) {
          loadDepartments()
        }
      }
    )
    watch(
      () => props.select_data,
      val => {
        const arr = JSON.parse(JSON.stringify(state.to_data))
        arr.push(...val)
        console.log('显示', arr)
        state.to_data = cutarray(arr)
        state.oldList = JSON.parse(JSON.stringify(cutarray(arr)))
      }
    )
    return {
      state,
      innerVisible,
      colseVisible,
      confirmSelect,
      leftloadNode,
      loadNode,
      duplicateRemoval,
      fromTreeChecked,
      toTreeChecked,
      selectedNode,
      unselectedNode,
      filterFrom,
      indexOf,
      ICON_FOLDER,
      ICON_PERSONNEL,
      rightLoadScroll
    }
  }
}
</script>

<style>
select {
  background-color: #fff;
}
.wl-transfer {
  position: relative;
  overflow: hidden;
}

.transfer-left {
  position: absolute;
  top: 0;
  left: 0;
}
.transfer-right {
  position: absolute;
  top: 0;
  right: 0;
}

.transfer-main {
  padding: 10px;
  height: calc(100% - 1px);
  box-sizing: border-box;
  overflow: auto;
}
.transfer-left,
.transfer-right {
  border: 1px solid #ebeef5;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  vertical-align: middle;
}

.filter-tree {
  margin-bottom: 10px;
}
.box-row-title {
  display: block;
  /* width: 100%; */
  height: 35px;
  margin: 10px 0 0 10px;
}
.box-row {
  display: block;
  /* width: 100%; */
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
}
.tree-show-more {
  color: #7c7cff;
  margin: 0;
}
</style>
